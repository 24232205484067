.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-hero {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-self: center;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #81b7a2;
}
.home-container01 {
  flex: 9;
  width: 642px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image {
  width: 246px;
  height: 236px;
  align-self: center;
  box-shadow: 2px 5px 30px 5px rgba(0, 0, 0, 0.26);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-text {
  color: #ffffff;
  font-size: 3rem;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  font-family: Poppins;
}
.home-text01 {
  color: #ffffff;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Poppins;
  font-weight: 200;
  line-height: 1.5;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-threeunits);
}
.home-social-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home-icon {
  fill: #ffffff;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon02 {
  fill: #ffffff;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon04 {
  fill: #ffffff;
  flex: 1;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  padding: auto;
  align-self: center;
}
.home-hero1 {
  width: 1018px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container02 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text02 {
  font-size: 3rem;
  max-width: 450px;
  font-family: Poppins;
}
.home-text03 {
  margin-top: var(--dl-space-space-twounits);
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-twounits);
}ge
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-button {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-black);
}
.home-image1 {
  width: 300px;
  object-fit: cover;
}
.home-gallery {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1522814208878-08129b4b76f3?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fGxpZ2h0aG91c2UlMjBwYXN0ZWx8ZW58MHx8fHwxNjI2NDM5Nzkx&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container03:hover {
  transform: scale(0.98);
}
.home-container04 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-color: #4c4c4c;
}
.home-text09 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text10 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDJ8fGdyZWVuJTIwbGFtcCUyMHBhc3RlbHxlbnwwfHx8fDE2MjY0Mzk4NDA&ixlib=rb-1.2.1&h=1000");
  background-position: center;
}
.home-container05:hover {
  transform: scale(0.98);
}
.home-container06 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-color: #688e4c;
}
.home-text11 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text13 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1528696892704-5e1122852276?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBpbmslMjB0ZWElMjBwYXN0ZWx8ZW58MHx8fHwxNjI2NDM5ODU2&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container07:hover {
  transform: scale(0.98);
}
.home-container08 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  background-color: #436e85;
}
.home-text15 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text16 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1497034825429-c343d7c6a68f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHllbGxvdyUyMGljZWNyZWFtfGVufDB8fHx8MTYyNjQzOTg4OQ&ixlib=rb-1.2.1&h=1000");
  background-position: center;
}
.home-container09:hover {
  transform: scale(0.98);
}
.home-container10 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-color: #fed172;
}
.home-text17 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text18 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1551500226-b50b653e33e8?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEwfHxwYXN0ZWwlMjBjb2ZmZWUlMjBncmVlbnxlbnwwfHx8fDE2MjY0Mzk5MzA&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container11:hover {
  transform: scale(0.98);
}
.home-container12 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-color: #79363a;
}
.home-text20 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text21 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1591101761702-0c4927df1d52?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHBhc3RlbCUyMHBpbmslMjBjb3R0b258ZW58MHx8fHwxNjI2NDQwMDUw&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container13:hover {
  transform: scale(0.98);
}
.home-container14 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-color: #c7c9cd;
}
.home-text23 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text24 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-link {
  display: contents;
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-hero {
    width: 100%;
    flex-direction: column;
  }
  .home-container01 {
    flex: 9;
    width: 100%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .home-text {
    text-align: center;
  }
  .home-text01 {
    text-align: center;
  }
  .home-social-bar {
    width: 100%;
  }
  .home-icon04 {
    flex: 1;
    position: static;
  }
  .home-hero1 {
    width: 100%;
    flex-direction: column;
  }
  .home-container02 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text02 {
    text-align: center;
  }
  .home-text03 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-gallery {
    grid-gap: var(--dl-space-space-unit);
  }
  .home-text09 {
    text-align: center;
  }
  .home-text10 {
    text-align: center;
  }
  .home-text11 {
    text-align: center;
  }
  .home-text13 {
    text-align: center;
  }
  .home-text15 {
    text-align: center;
  }
  .home-text16 {
    text-align: center;
  }
  .home-text17 {
    text-align: center;
  }
  .home-text18 {
    text-align: center;
  }
  .home-text20 {
    text-align: center;
  }
  .home-text21 {
    text-align: center;
  }
  .home-text23 {
    text-align: center;
  }
  .home-text24 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-hero {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container01 {
    width: 100%;
  }
  .home-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-social-bar {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text03 {
    line-height: 1.5;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image1 {
    width: 100px;
  }
  .home-gallery {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-container08 {
    padding: var(--dl-space-space-unit);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text25 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    width: 100%;
    position: relative;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-image {
    width: 297px;
    height: 291px;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-text {
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    font-weight: 600;
  }
  .home-text01 {
    width: auto;
    align-self: center;
  }
  .home-social-bar {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container02 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text03 {
    line-height: 1.5;
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-image1 {
    width: 100;
  }
  .home-gallery {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .home-container08 {
    padding: var(--dl-space-space-unit);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-text25 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
